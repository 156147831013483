import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const TTChopper = () => {
    const tchop = useStaticQuery(graphql`
    {
        allWpGhomepage {
          edges {
            node {
              homepageLayout {
                homepageLayout {
                  ... on WpGhomepage_Homepagelayout_HomepageLayout_SvgRight {
                    aosInstructions
                    blockId
                    fieldGroupName
                    leftContent
                    leftSectionClass
                    rightSectionClass
                    rightSvg
                  }
                }
              }
            }
          }
        }
    }
      
    `)
    return (
        <article id={tchop.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[2].blockId}>
			<div className="block-wrapper">
				<section className={tchop.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[2].leftSectionClass}  data-aos="spirit-text" data-aos-offset="-190" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" data-aos-anchor-placement="top-center" dangerouslySetInnerHTML={{__html: tchop.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[2].leftContent}}>
					
				</section>
				<section className={tchop.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[2].rightSectionClass} data-aos="spirit-symbols" data-aos-offset="-190" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" data-aos-anchor-placement="top-center" dangerouslySetInnerHTML={{__html: tchop.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[2].rightSvg}}>
					
				</section>
			</div>
		</article>
    )

}



export default TTChopper;