import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Pianist = () => {
    const pianoman = useStaticQuery(graphql`
    {
      allWpGhomepage {
        edges {
          node {
            homepageLayout {
              homepageLayout {
                ... on WpGhomepage_Homepagelayout_HomepageLayout_SvgLeft {
                  aosInstructions
                  blockId
                  fieldGroupName
                  leftSectionClass
                  leftSvg
                  rightContent
                  rightSectionClass
                }
              }
            }
          }
        }
      }
    }
      
    `)
    return (
        <article id={pianoman.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[3].blockId}>
			<div className="block-wrapper">
				<section className={pianoman.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[3].leftSectionClass}  data-aos="piano-anim" data-aos-offset="-100" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" data-aos-anchor-placement="top-center" dangerouslySetInnerHTML={{__html: pianoman.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[3].leftSvg}}>
					
				</section>
				<section className={pianoman.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[3].rightSectionClass} dangerouslySetInnerHTML={{__html: pianoman.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[3].rightContent}}>
					
				</section>
			</div>
		</article>
    )

}



export default Pianist;