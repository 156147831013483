import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const FooterAside = () => {
    const footaside = useStaticQuery(graphql`
    {
        allWpGhomepage {
          edges {
            node {
              homepageLayout {
                homepageLayout {
                  ... on WpGhomepage_Homepagelayout_HomepageLayout_AsidePreFooter {
                    aosInstructions
                    asideId
                    asideTitle
                    ctaClass
                    ctaText
                    ctaUrl {
                      ... on WpPage {
                        id
                        uri
                        link
                        slug
                      }
                    }
                    wrapperClass
                  }
                }
              }
            }
          }
        }
      }
      
    `)
    return (
        <aside id={footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].asideId} data-aos="fcta-text" data-aos-offset="-190" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" data-aos-anchor-placement="top-center">
			<section className={footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].wrapperClass}>
                <span dangerouslySetInnerHTML={{__html: footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].asideTitle}}/>
                <AniLink cover direction="right" duration={1} bg="#4d0011" to={footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].ctaUrl.uri} className={footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].ctaClass}>
                    {footaside.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[5].ctaText}
                </AniLink>
			</section>
		</aside>
    )

}



export default FooterAside;