import React, { Component } from "react"
import AOS from 'aos';
import  HomeHero  from "../components/homepageHero.js"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SpiritSymb from "../components/hSpiritSymbols";
import TTChopper from "../components/hTonyTonyChopper";
import Pianist from "../components/hPianist";
import Gaming from "../components/hGaming";
import FooterAside from "../components/hAside";



class IndexPage extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init();
  }    
  
  componentDidUpdate() {
    this.aos.refresh();
  }

   render () {
    return (
      <Layout>
      <Seo title="About me" />
      <HomeHero></HomeHero>
      <SpiritSymb></SpiritSymb>
      <TTChopper></TTChopper>
      <Pianist></Pianist>
      <Gaming></Gaming>
      <FooterAside></FooterAside>
    </Layout>
    )
   }
}

export default IndexPage