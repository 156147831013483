import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import  StraplineRight  from "./hhStraplineRight"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const HomeHero = () => {

  const herolay = useStaticQuery(graphql`
      {
        allWpGhomepage {
          edges {
            node {
              homepageLayout {
                homepageLayout {
                  ... on WpGhomepage_Homepagelayout_HomepageLayout_HomepageBlocks {
                    straplines {
                      aniWrapClose
                      aniWrapOpen
                      fieldGroupName
                      headingFourClass
                      headingFourText
                      headingOneClass
                      headingOneText
                      headingThreeClass
                      headingThreeText
                      headingTwoClass
                      headingTwoText
                      straplineClass
                      svgCta
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
    return (
      <section id="hero">
        <div id="heroWrapper">
          <div className="hero_left">
            <div id="strapline">
            
              <div key={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].straplineClass} className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].straplineClass}>
                <h1>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingOneClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingOneText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingTwoClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingTwoText}</div><br />
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingThreeClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingThreeText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingFourClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].headingFourText}</div>
							  </h1>
                <span dangerouslySetInnerHTML={{__html: herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[0].svgCta}} />
              </div>
            
              <div key={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].straplineClass} className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].straplineClass}>
                
							<div className="ani-wrap">
                <h1>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingOneClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingOneText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingTwoClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingTwoText}</div><br />
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingThreeClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingThreeText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingFourClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].headingFourText}</div>
							  </h1>
                <span dangerouslySetInnerHTML={{__html: herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[1].svgCta}} />
                </div>
              </div>
            
              <div key={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].straplineClass} className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].straplineClass}>
                
							<div className="ani-wrap">
                <h1>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingOneClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingOneText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingTwoClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingTwoText}</div><br />
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingThreeClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingThreeText}</div>
                  <div className={herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingFourClass}>{herolay.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplines[2].headingFourText}</div>
							  </h1>
                <span>
                  <AnchorLink to="#home-block-one" className="scroll-to-cta scroll">Scroll to learn more</AnchorLink>
                </span>
                </div>
              </div>
            
            </div>
          </div>
          <StraplineRight></StraplineRight>
          
        </div>
      </section>
    )
}

export default HomeHero;