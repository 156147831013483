import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const StraplineRight = () => {
    const data = useStaticQuery(graphql`
    query {
        allWpGhomepage {
          edges {
            node {
              homepageLayout {
                homepageLayout {
                  ... on WpGhomepage_Homepagelayout_HomepageLayout_HomepageBlocks {
                    straplineRight
                  }
                }
              }
            }
          }
        }
      }
    `)
    return (
        <div className="hero_right" dangerouslySetInnerHTML={{__html: data.allWpGhomepage.edges[0].node.homepageLayout.homepageLayout[0].straplineRight}} />
    )

}



export default StraplineRight;